import { PureComponent } from 'react';
import ProfileProvider from 'components/core/Provider/ProfileProvider';
import StoreProvider from 'components/core/Provider/Store';

export class MainComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { Component, query, pageProps } = this.props;

    return (
      <StoreProvider>
        <ProfileProvider query={query}>
          <Component {...pageProps} />
        </ProfileProvider>
      </StoreProvider>
    )
  }
}

export default MainComponent;
