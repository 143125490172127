import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* First we will make a new context */
export const ProfileContext = React.createContext();

/* Then create a provider Component */
const ProfileProvider = ({ children, query }) => {
  const information = {
    sidebar: {
      opened: false,
      classOpened: 'open'
    },
    otherProducts: null,
    showIframe: false,
    showReviewsPage: false,
    checkoutLoaded: false,
    menu: {
      opened: false,
      submenu: false,
      changeLanguage: false,
      search: false,
      sign: false
    },
    query: { ...query },
    featureSwitch: {},
    checkout: {
      opened: false,
      classOpened: 'open',
      data: {},
      off: '',
      src: '',
      sck: '',
      coupon: '',
      hotfeature: '',
      onlineService: false,
      modalOnlineService: false,
      loading: true,
      modal: false
    },
    online_service: {
      highlightDays: [],
      availableHours: [],
      sessionInfo: {
        attendanceTimesPerWeek: null,
        numberOfSessions: null,
        sessionFrequency: null,
        attendanceSlotSize: 1,
        sessionType: 'SINGLE'
      }
    },
    abTestFlags: {},
    profile: {},
    isModalOpen: false,
    actions: {
      setProfile: (profile) => setProfile(profile),
      setCheckout: (checkout, off, src, sck, coupon, hotfeature) =>
        setCheckout(checkout, off, src, sck, coupon, hotfeature),
      storeLanguage: (lang) => storeLanguage(lang),
      setOtherProducts: (otherProducts) => setOtherProducts(otherProducts),
      setShowReviewsPage: (value) => setShowReviewsPage(value),
      openScheduler: (open) => openScheduler(open),
      openModalScheduler: (open) => openModalScheduler(open),
      openModal: (open) => openModal(open),
      setDates: (dates) => setDates(dates),
      setIsModalOpen: (isModalOpen) => setIsModalOpen(isModalOpen),
      setSessionInfo: (sessionInfo) => {
        if (sessionInfo) {
          setSessionInfo(sessionInfo);
        } else {
          setSessionInfo({
            attendanceTimesPerWeek: null,
            numberOfSessions: null,
            sessionFrequency: null,
            availableDays: null,
            attendanceSlotSize: 1,
            sessionType: 'SINGLE'
          });
        }
      },
      setHours: (dates) => setHours(dates),
      setCheckoutLoading: (loading) => setCheckoutLoading(loading),
      setReviewAnswers: (reviews) => setReviewAnswers(reviews),
      setFeatureSwitch: (featureSwitch) => setFeatureSwitch(featureSwitch),
      setAbTestFlags: (testFlags) => setAbTestFlags(testFlags)
    },
    changeState: (property, value) => {
      setInfo((prevState) => {
        return {
          ...prevState,
          [property]: value
        };
      });
    }
  };

  const [info, setInfo] = useState(information);

  const ctx = React.useMemo(
    () => ({
      info,
      setInfo
    }),
    [info]
  );

  const setShowReviewsPage = (value) => {
    information.changeState('showReviewsPage', value);
  };

  const setOtherProducts = (otherProducts) => {
    information.changeState('otherProducts', otherProducts);
  };

  const storeLanguage = (lang) => {
    information.checkout.language = lang;
    information.changeState('checkout', information.checkout);
  };

  const setProfile = (profile) => {
    information.changeState('profile', profile);
  };

  const setCheckout = (checkout, off, src, sck, coupon, hotfeature) => {
    information.checkout.data = checkout;
    information.checkout.off = off;
    information.checkout.src = src;
    information.checkout.sck = sck || 'HOTMART_PRODUCT_PAGE';
    information.checkout.coupon = coupon;
    information.checkout.hotfeature = hotfeature;
    information.changeState('checkout', information.checkout);
  };

  const openScheduler = (open) => {
    information.checkout.onlineService = open;
    information.changeState('checkout', information.checkout);
  };

  const openModalScheduler = (open) => {
    information.checkout.modalOnlineService = open;
    information.changeState('checkout', information.checkout);
  };

  const openModal = (open) => {
    information.checkout.modal = open;
    information.changeState('checkout', information.checkout);
  };

  const setDates = (dates) => {
    information.online_service.highlightDays = dates;
    information.changeState('online_service', information.online_service);
  };

  const setHours = (hours) => {
    information.online_service.availableHours = hours;
    information.changeState('online_service', information.online_service);
  };

  const setSessionInfo = (sessionInfo) => {
    information.online_service.sessionInfo = sessionInfo;
    information.changeState('online_service', information.online_service);
  };

  const setCheckoutLoading = (loading) => {
    information.checkout.loading = loading;
    information.changeState('checkout', information.checkout);
  };

  const setReviewAnswers = (reviews, profile) => {
    profile.reviews = reviews;
    information.profile = profile;
    information.changeState('profile', information.profile);
  };

  const setFeatureSwitch = (featureSwitch) => {
    information.featureSwitch = featureSwitch;
    information.changeState('featureSwitch', information.featureSwitch);
  };

  const setAbTestFlags = (testFlags) => {
    information.abTestFlags = testFlags;
    information.changeState('abTestFlags', information.abTestFlags);
  };

  const setIsModalOpen = (isModalOpen) => {
    information.isModalOpen = isModalOpen;
    information.changeState('isModalOpen', information.isModalOpen);
  };

  return (
    <ProfileContext.Provider value={ctx.info}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.object.isRequired
};

/* then make a consumer which will surface it */
const ProfileConsumer = ProfileProvider.Consumer;

export default ProfileProvider;
export { ProfileConsumer };
