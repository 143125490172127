import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'

/* First we will make a new context */
export const StoreContext = React.createContext();
const STORAGE = 'profile_storage';

/* Then create a provider Component */
const StoreProvider = (props) => {
  const information = {
    user: {
      name: '',
      authorities: []
    },
    tokenProfile: null,
    actions: {
      clean: () => clean,
      setUser: (user) => setUser(user),
      setToken: (token) => setToken(token),
      saveStorage: (user, token) => saveStorage(user, token),
      incrementUser: (user) => incrementUser(user),
      isLogged: () => isLogged,
      haveRights: (authoritie) => haveRights(authoritie)
    },
    changeState: (property, value) => {
      setInfo( prevState => {
        return {
          ...prevState,
          [property]: value
        }
      })
    }
  };

  const [info, setInfo] = useState(information);

  const setUser = user => {
    info.changeState('user', user)
  };

  const incrementUser = async (user) => {
    const userPromise = await user;
    const userRedefined = { ...info.user, ...userPromise};

    info.changeState('user', userRedefined);
  };

  const clean = () => {
    info.changeState('user', { name: ''});
    info.changeState('tokenProfile', '');
    localStorage.removeItem(STORAGE);
    localStorage.removeItem('tokenProfile');
    localStorage.removeItem('token');
    cookie.remove('hmVlcIntegration', { path: '/' });
  };

  const isLogged = () => !!info.tokenProfile;

  const saveStorage = (user, token) => {
    localStorage.setItem(STORAGE, JSON.stringify({ ...user, tokenProfile: token}));
  };

  const setToken = (token) => {
    info.changeState('tokenProfile', token);
    localStorage.setItem('tokenProfile', token);
  };

  const haveRights = (authoritie) => {
    const rights = this.state.user.authorities.find(rule => rule === authoritie);
    return rights && rights.length >= 1;
  }

  return (
    <StoreContext.Provider value={info}>
      {props.children}
    </StoreContext.Provider>
  )
}

/* then make a consumer which will surface it */
const StoreConsumer = StoreProvider.Consumer;

export default StoreProvider
export { StoreConsumer }
